<template>
	<div>
		<form id="priceListAddNewPriceForm">
			<vue-form-generator
				@validated="onFormValidated"
				:schema="schemaPriceListAddNewPrice"
				:model="modelPriceListAddNewPrice"
				:options="formOptionsPriceListAddNewPrice"
			>
			</vue-form-generator>
		</form>
		<div>
			<b-button
				:disabled="!!toggleAddButton"
				class="is-primary"
				type="submit"
				@click="onSubmit"
				>Save Customer's Price
			</b-button>
		</div>
	</div>
</template>

<script>
// Cleave
// eslint-disable-next-line no-unused-vars
import cleave from 'cleave.js'
import { reactive, ref } from '@vue/composition-api'
import Store from '@/store'
import { useMutation } from '@vue/apollo-composable'
import AddCustomerPriceMutation from '@/graphql/mutations/customer/customerPriceList/addCustomersPrice.mutation.gql'
import useConvertToInteger from '@/composables/stock/convertToInteger'

export default {
	setup() {
		const { convertToInteger } = useConvertToInteger()
		const modelPriceListAddNewPrice = reactive({
			company: Store.getters.getPLCurrentCompany || '',
			customerID: Store.getters.getPLSelectedCustomerID || '',
			stockID: Store.getters.getPLStockItemID || '',
			stockItemName: Store.getters.getPLStockItemName || '',
			stockItemPrice: Store.getters.getPLStockItemPrice || '',
			customersPrice: Store.getters.getPLCustomersPrice || 0,
		})
		const schemaPriceListAddNewPrice = reactive({
			groups: [
				{
					legend: 'Price Headers',
					id: 'headers',
					featured: true,
					fields: [
						{
							type: 'input',
							inputType: 'text',
							label: 'Our Company',
							model: 'company',
							id: 'company_name',
							readonly: true,
							featured: true,
							disabled: true,
							required: true,
						},
						{
							type: 'input',
							inputType: 'text',
							label: 'Customer',
							model: 'customerID',
							id: 'customer_id',
							readonly: true,
							featured: true,
							disabled: true,
							required: true,
						},
						{
							type: 'input',
							inputType: 'text',
							label: 'Stock ID',
							model: 'stockID',
							id: 'stock_id',
							readonly: true,
							featured: true,
							disabled: true,
							required: true,
						},
						{
							type: 'input',
							inputType: 'text',
							label: 'Stock Item Name',
							model: 'stockItemName',
							id: 'stock_item_name',
							readonly: true,
							featured: true,
							disabled: true,
							required: true,
						},
						{
							type: 'input',
							inputType: 'text',
							label: 'Stock Item Price',
							model: 'stockItemPrice',
							id: 'stock_item_price',
							readonly: true,
							featured: true,
							disabled: true,
							required: true,
						},
						{
							type: 'cleave',
							label: 'Customers Price',
							model: 'customersPrice',
							placeholder: 'Please enter customers price',
							featured: true,
							required: true,
							validator: ['positivenumber', 'required'],
							cleaveOptions: {
								numeral: true,
								numeralThousandsGroupStyle: 'thousand',
								numeralDecimalScale: 2,
								numeralDecimalMark: ',',
							},
							onValidated: function (model, errors) {
								if (errors.length > 0)
									console.warn(
										'Validation error in Price field! Errors:',
										errors,
									)
								else {
									// localStorage.setItem('stockItemPrice', model.price)
									Store.dispatch('setPLCustomersPrice', model.customersPrice)
								}
							},
						},
					],
				},
			],
		})
		const formOptionsPriceListAddNewPrice = reactive({
			validateAfterLoad: true,
			validateAfterChanged: true,
			validateAsync: true,
			fieldIdPrefix: 'pricelistaddnewprice',
		})
		const onDisableEnableToggle = (payload) => {
			schemaPriceListAddNewPrice.groups[0].fields[1].disabled = payload
		}
		const toggleAddButton = ref(true)
		const onFormValidated = (isValid, errors) => {
			if (isValid === true) {
				toggleAddButton.value = false
			} else if (errors.length !== 0) {
				toggleAddButton.value = true
			}
		}
		// Vue Apollo mutation Snippet - Don't forget to import mutation from graphQL folder
		// Add new Dispatch
		const { mutate: addNewPrice, onDone } = useMutation(
			AddCustomerPriceMutation,
			() => ({
				variables: {
					company_id: modelPriceListAddNewPrice.company,
					customer_id: modelPriceListAddNewPrice.customerID,
					stock_id: modelPriceListAddNewPrice.stockID,
					customers_price: convertToInteger(
						modelPriceListAddNewPrice.customersPrice,
					),
				},
			}),
		)
		const onSubmit = () => {
			addNewPrice()
			// Store.dispatch('setPLSearchStockItemShow', true)
			// Store.dispatch('setPLCustomersPriceShow', false)
		}
		onDone(() => {
			Store.dispatch('setPLSearchStockItemShow', true)
			Store.dispatch('setPLCustomersPriceShow', false)
			Store.dispatch(
				'setPLCustomersPriceLinesKey',
				Store.getters.getPLCustomersPriceLinesKey + 1,
			)
		})
		return {
			schemaPriceListAddNewPrice,
			modelPriceListAddNewPrice,
			formOptionsPriceListAddNewPrice,
			onDisableEnableToggle,
			onFormValidated,
			toggleAddButton,
			onSubmit,
		}
	},
}
</script>
